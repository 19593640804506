import Vue from "vue"
import store from "./store"
import Router from "vue-router"
import routerMiddleware from "./router-middleware"

Vue.use(Router)

const router = new Router({
	mode: "history",
	base: "/",
	linkActiveClass: "active",
	linkExactActiveClass: "active",
	scrollBehavior: (to, from, savedPosition) => {
		if (to.hash) {
			return {
				selector: to.hash
			}
		}else if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	},
	routes: [

		// ===========================
		// Authentication
		// ===========================
		{
			name: "auth",
			path: "/",
			redirect: {
				name: "login"
			},
			meta: {
				guest: true
			},
			component: require(/* webpackChunkName: "auth" */"./views/auth/Index.vue").default,
			children: [
				{
					name: "login",
					path: "/auth/login",
					component: require(/* webpackChunkName: "login" */"./views/auth/Login.vue").default,
					meta: {
						title: "Vault24 Admin - User Login"
					}
				}
			]
		},

		// ===========================
		// Administration
		// ===========================
		{
			name: "admin",
			path: "/admin",
			redirect: {
				name: "home"
			},
			meta: {
				requiresAuth: true
			},
			component: require(/* webpackChunkName: "admin" */"./views/admin/Index.vue").default,
			children: [
				{
					name: "home",
					path: "/admin/home",
					component: require(/* webpackChunkName: "home" */"./views/admin/Home.vue").default,
					meta: {
						title: "Vault24 Admin - Home"
					}
				},
				{
					name: "savings",
					path: "/admin/savings",
					component: require(/* webpackChunkName: "savings" */"./views/admin/Savings.vue").default,
					meta: {
						title: "Vault24 Admin - Savings"
					}
				},
				{
					name: "customers",
					path: "/admin/customers",
					component: require(/* webpackChunkName: "customers" */"./views/admin/Customers.vue").default,
					meta: {
						title: "Vault24 Admin - Customers"
					}
				},
				{
					name: "transactions",
					path: "/admin/transactions",
					component: require(/* webpackChunkName: "transactions" */"./views/admin/Transactions.vue").default,
					meta: {
						title: "Vault24 Admin - Transactions"
					}
				},
				{
					name: "credits",
					path: "/admin/credits",
					component: require(/* webpackChunkName: "credits" */"./views/admin/Credits.vue").default,
					meta: {
						title: "Vault24 Admin - Credits"
					}
				},
				{
					name: "reports",
					path: "/admin/reports",
					component: require(/* webpackChunkName: "reports" */"./views/admin/Reports.vue").default,
					meta: {
						title: "Vault24 Admin - Reports"
					}
				},
				{
					name: "withdrawals",
					path: "/admin/withdrawals",
					component: require(/* webpackChunkName: "withdrawals" */"./views/admin/Withdrawals.vue").default,
					meta: {
						title: "Vault24 Admin - Withdrawals"
					}
				},
				{
					name: "support",
					path: "/admin/support",
					component: require(/* webpackChunkName: "support" */"./views/admin/Support.vue").default,
					meta: {
						title: "Vault24 Admin - Support"
					}
				},
				{
					name: "settings",
					path: "/admin/settings",
					redirect: {
						name: "product-management"
					},
					meta: {
						requiresAuth: true
					},
					component: require(/* webpackChunkName: "settings" */"./views/admin/settings/Index.vue").default,
					children: [
						{
							name: "product-management",
							path: "/admin/settings/product-management",
							component: require(/* webpackChunkName: "product-management" */"./views/admin/settings/ProductManagement.vue").default,
							meta: {
								title: "Vault24 Admin - Product Management"
							}
						},
						{
							name: "user-management",
							path: "/admin/settings/user-management",
							component: require(/* webpackChunkName: "user-management" */"./views/admin/settings/UserManagement.vue").default,
							meta: {
								title: "Vault24 Admin - User Management"
							}
						}
					]
				}
			]
		},
	]
})

router.beforeEach(async (to, from, next) => {
	await routerMiddleware(router, to, from, next)
	next();
})

router.afterEach(to => {
	document.title = to.meta.title		
})

export default router