import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		user: require('./store/user').default,
		alerts: require('./store/alerts').default,
		form: require('./store/form').default,
		http: require('./store/http').default,
	},
	state: {

	},
	mutations: {

	},
	actions: {

	}
})
