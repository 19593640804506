<template>
	<div id="home">
		<div class="user--greeting">
			<h5 class="vault--text--primary">Good Morning, {{user.firstname}} {{user.lastname}}</h5>
			<p class="">Here's what is happening in vault24 today.</p>
		</div>
		<div class="my-5" v-if="loading">
			<ajax-loader loadingText='loading stats...'/>
		</div>
		<div class="stats--cards mb-5" v-else>
			<div class="card stats--card stats--card--gradient">
				<div class="stats--card--content">
					<div class="stats--card--icon">
						<ion-icon name="shield-half-outline" class="text-white"></ion-icon>
					</div>
					<div class="stats--card--text">
						<h6 class="text-white">Total Vault Savings</h6>
						<h5 class="text-white">
							₦{{(stats.totalSavings ? stats.totalSavings : '0.00') | currency}}
						</h5>
					</div>
				</div>
			</div>
			<div class="card stats--card">
				<div class="stats--card--content">
					<div class="stats--card--icon">
						<ion-icon name="trending-up-outline" class="text-success"></ion-icon>
					</div>
					<div class="stats--card--text">
						<h6 class="text--muted">Total Interest Accured</h6>
						<h5 class="vault--text--primary">
							₦{{(stats.totalInterest ? stats.totalInterest : '0.00') | currency}}
						</h5>
					</div>
				</div>
			</div>
			<div class="card stats--card stats--card--gradient">
				<div class="stats--card--content">
					<div class="stats--card--icon">
						<ion-icon name="people-outline" class="text-white"></ion-icon>
					</div>
					<div class="stats--card--text">
						<h6 class="text-white">Customers</h6>
						<h5 class="text-white">{{stats.customers}}</h5>
					</div>
				</div>
			</div>
			<div class="card stats--card">
				<div class="stats--card--content">
					<div class="stats--card--icon">
						<img src="/svg/red-alert.svg" alt="" width="32">
					</div>
					<div class="stats--card--text">
						<h6 class="text--muted">Disputes</h6>
						<h5 class="vault--text--primary">0</h5>
					</div>
				</div>
			</div>
		</div>
		<div class="dashboard--cols--section">
			<div>
				<div class="page--header mb-3">
					<div class="d-flex justify-content-between align-items-center">
						<h5 class="vault--text--primary m-0">Upcoming Withdrawals</h5>
						<h6 class="text-uppercase m-0" style="font-weight: 400; font-size: .9rem"><u>See All</u></h6>
					</div>
				</div>
				<div class="card withdrawal--card mb-2">
					<div class="withdrawal--card--content">
						<div class="withdrawal--card--details">
							<div class="withdrawal--card--icon--box bg-rent">
								<img src="/svg/withdraw-icon.svg" alt="" width="20">
							</div>
							<div class="withdrawal--card--text">
								<p class="withdrawal--name m-0">John Anayo - ₦32,990</p>
								<p class="withdrawal--time m-0">Due: 21/Jan/2021</p>
							</div>
						</div>
						<div class="withdrawal--card--product">
							<h6 class="rent-color m-0">RentPlus</h6>
						</div>
					</div>
				</div>
				<div class="card withdrawal--card mb-2">
					<div class="withdrawal--card--content">
						<div class="withdrawal--card--details">
							<div class="withdrawal--card--icon--box bg-target">
								<img src="/svg/withdraw-icon.svg" alt="" width="20">
							</div>
							<div class="withdrawal--card--text">
								<p class="withdrawal--name m-0">John Anayo - ₦32,990</p>
								<p class="withdrawal--time m-0">Due: 21/Jan/2021</p>
							</div>
						</div>
						<div class="withdrawal--card--product">
							<h6 class="target-color m-0">Target Savings</h6>
						</div>
					</div>
				</div>
				<div class="card withdrawal--card mb-2">
					<div class="withdrawal--card--content">
						<div class="withdrawal--card--details">
							<div class="withdrawal--card--icon--box bg-vault">
								<img src="/svg/withdraw-icon.svg" alt="" width="20">
							</div>
							<div class="withdrawal--card--text">
								<p class="withdrawal--name m-0">John Anayo - ₦32,990</p>
								<p class="withdrawal--time m-0">Due: 21/Jan/2021</p>
							</div>
						</div>
						<div class="withdrawal--card--product">
							<h6 class="vault-color m-0">Vault Lock</h6>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div class="page--header mb-3">
					<h5 class="vault--text--primary">Saving Plans</h5>
				</div>
				<div class="card p-3">
					<canvas ref="pieChart" height="180" width="300"></canvas>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Chart from "chart.js"
	import { mapActions, mapState } from "vuex"
	export default {
		data() {
			return {
				loading: true,
				stats: {}
			}
		},
		mounted() {
			this.renderPieChart()
			this.loadDashboardStats()
		},
		computed: {
			...mapState('user/session', ['user'])
		},
		methods: {
			...mapActions("http", ["post", "get"]),
			async loadDashboardStats() {
				this.loading = true 

                var { response, error } = await this.get({
                    url: `${this.$baseURL}/dashboard/stats`,
                    headers: this.ajax_headers,
                    formErrors: false
                })

                this.loading = false

                if (response && response.data.success) {
                    this.stats = response.data.stats
                }
			},
			renderPieChart() {
				let ctx = this.$refs.pieChart.getContext("2d")
				var myChart = new Chart(ctx, {
				    type: 'pie',
				    data: {
				        labels: ['RentPlus', 'Target Savings', 'Vault Lock', 'Little Drops', 'Kapital', 'Wishlist'],
				        datasets: [
				        	{
					            label: 'Plan Balance',
					            data: [280, 300, 380, 80, 250, 80],
					            backgroundColor: [
					                '#2D62ED',
					                '#410ADD',
					                '#DC0000',
					                '#E83C8F',
					                '#F88600',
					                '#990F94'
					            ]
					        }
				        ]
				    },
				    options: {
				    	responsive: true,
				    	labels: {
				    		display: false
				    	},
				    	legend: {
				    		position: 'right',
				    		labels: {
				    			padding: 10,
						      	usePointStyle: true,
						      	boxWidth: 6
						    }
				    	},
				        scales: {
				            xAxes: [{
				            	display: false
					        }],
					        yAxes: [{
					            display: false
					        }]
				        }
				    }
				})
			}
		}
	}
</script>