<template>
	<div class="nav-bar">
		<nav class="navbar top-nav navbar-expand-lg bg-white py-3 fixed-top">
			<div class="container">
			  	<router-link class="navbar-brand" :to="{name: 'home'}">
			  		<img src="/svg/vault-logo-dark.svg" width="150">
			  	</router-link>
			  	<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
			    	<span class="navbar-toggler-icon"></span>
			  	</button>
			  	<div class="collapse navbar-collapse d-flex align-items-center" id="navbarNavDropdown">
				    <ul class="navbar-nav ml-auto">
				      	<li class="nav-item mr-2">
				        	<a href="#" class="notification--nav">
				        		<ion-icon name="notifications-outline" style="font-size: 30px; --ionicon-stroke-width: 16px;" class="text--muted"></ion-icon>
				        		<div class="notification--indicator"></div>
				        	</a>
				      	</li>
				    </ul>
			  	</div>
		  	</div>
		</nav>
	</div>
</template>

<script type="text/javascript">
	import { mapActions } from "vuex"
	export default {
		methods: {
			...mapActions("user/session", ["logout"]),
			logOut() {
				this.logout()
				this.$router.push({ name: "login" })
			}
		}
	}
</script>

<style type="text/css" scoped>
	.dropdown-menu {
		border: 1px solid #EAEAEA;
		width: 300px;
	}

	.dropdown-menu .dropdown-item:hover {
		background: transparent;
	}

	.dropdown-menu:before {
  		position: absolute;
  		top: -7px;
  		right: 9px;
  		display: inline-block;
  		border-right: 7px solid transparent;
  		border-bottom: 7px solid #EAEAEA;
  		border-left: 7px solid transparent;
  		border-bottom-color: #EAEAEA;
  		content: "";
	}

	.dropdown-menu:after {
  		position: absolute;
  		top: -6px;
  		right: 10px;
  		display: inline-block;
  		border-right: 6px solid transparent;
  		border-bottom: 6px solid #ffffff;
  		border-left: 6px solid transparent;
  		content: "";
	}
</style>