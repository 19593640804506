import Vue from 'vue';

const components = [
	{
		name: 'side-nav',
		path: 'layouts/SideNav'
	},
	{
		name: 'top-nav',
		path: 'layouts/TopNav'
	},
	{
		name: 'ajax-loader',
		path: 'widgets/AjaxLoader'
	}
];

components.forEach(component => {
	Vue.component(component.name, require(`./components/${component.path}.vue`).default);
});
