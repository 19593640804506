<template>
	<div id="withdrawals">
		<div class="page--header mb-4">
			<h5 class="vault--text--primary">Withdrawals</h5>
		</div>
		<div v-if="loading">
			<div class="d-flex justify-content-center">
				<ajax-loader loadingText="loading..." />
			</div>
		</div>
		<div class="card table-responsive pb-0" v-else-if="requests && requests.length">
			<table class="table mb-0">
			  	<thead>
			    	<tr>
			      		<th scope="col">Customer</th>
			      		<th scope="col">Amount</th>
			      		<th scope="col">Date Requested</th>
			      		<th scope="col">Status</th>
			      		<th scope="col">Action</th>
			    	</tr>
			  	</thead>
			  	<tbody>
			  		<template v-for="(request, n) in requests">
				    	<tr :key="n">
				      		<td class="d-flex justify-content-start align-items-center td--img">
				      			<img src="/img/black-woman.jpeg">
				      			<span class="ml-3 text-capitalize">
				      				{{request.owner && request.owner.firstname}} {{request.owner && request.owner.lastname}}
				      			</span>
				      		</td>
				      		<td>₦{{request.amount | currency}}</td>
				      		<td>{{request.createdAt | toDate}}</td>
				      		<td>
				      			<div class="d-flex justify-content-start align-items-center">
					      			<div 
					      				class="dot default" 
					      				:class="request.status === 0 
					      					? 'default' : request.status === 1 
					      					? 'success' : 'danger'">
					      			</div>
					      			<span class="ml-2" v-if="request.status === 0">Awaiting Approval</span>
					      			<span class="ml-2" v-else-if="request.status === 1">Approved</span>
					      			<span class="ml-2" v-else>Transfer Failed</span>
					      		</div>
				      		</td>
				      		<td>
				      			<div class="d-flex justify-content-start align-items-center">
					      			<button 
					      				class="btn btn--xs btn--vault--primary" 
					      				@click="callApproveModal(request._id)"
					      			>
					      				Approve
					      			</button>
					      			<button class="btn btn--xs btn--danger ml-2">Decline</button>
					      		</div>
				      		</td>
				    	</tr>
			    	</template>
			  	</tbody>
			</table>
		</div>
		<div class="text-center mt-5" v-else>
			<h5 class="mb-4">No Payout request(s) at the moment</h5>
		</div>
		<div class="modal fade"tabindex="-1" role="dialog" aria-hidden="true" ref="approve_modal">
		  	<div class="modal-dialog modal-dialog-centered" role="document">
		    	<div class="modal-content">
		      		<div class="modal-body p-4">
		      			<div class="d-flex justify-content-end mb-4">
			      			<div 
			      				class="close"
			      				@click="closeModal"
			      				:style="{cursor: verify.loading ? 'not-allowed' : 'pointer'}"
			      				data-dismiss="modal" aria-label="Close"
			      			>
			          			<ion-icon name="close-circle-outline" class="cursor vault--text--primary"/>
			        		</div>
			        	</div>
			        	<template v-if="verify.data">
				        	<h6 class="vault--text--primary mb-2">Account Details</h6>
				        	<table class="table table-bordered mb-4">
				        		<tbody>
				        			<tr>
				        				<td>Bank Name</td>
				        				<td class="text-capitalize">{{verify.data.account_name}}</td>
				        			</tr>
				        			<tr>
				        				<td>Account Number</td>
				        				<td>{{verify.data.account_number}}</td>
				        			</tr>
				        		</tbody>
				        	</table>
				        	<div class="text-center">
				        		<button class="btn btn--sm btn--vault--primary">
				        			<span v-if="transfer.loading">Loading...</span>
				        			<span v-else>Continue to payout</span>
				        		</button>
								<button class="btn btn--sm btn--vault--primary ml-2" @click="confirmPayout">
				        			<span v-if="confirm.loading">Loading...</span>
				        			<span v-else>Confirm Payment</span>
				        		</button>
				        	</div>
			        	</template>
			        	<template v-else>
			        		<h5 class="mb-4">Are you sure you want to approve this request?</h5>
			        		<div class="text-center">
			        			<button 
			        				class="btn btn--sm btn--vault--primary"
			        				:disabled="verify.loading"
			        				@click="verifyAccount"
			        			>
			        				<span v-if="verify.loading">Verifying account...</span>
			        				<span v-else>Verify account</span>
			        			</button>
			        			<button 
			        				:disabled="verify.loading"
			        				class="btn btn--sm btn--danger ml-3"
			        			>	
			        				Cancel
			        			</button>
			        		</div>
			        	</template>
			        </div>
			    </div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import $ from 'jquery'
	import {mapActions} from 'vuex'
	export default {
		data() {
			return {
				loading: false,
				requests: null,
				verify: {
					loading: false,
					data: null
				},
				transfer: {
					loading: false
				},
				confirm: {
					loading: false
				},
				request_id: ''
			}
		},
		mounted() {
			this.loadRequests()
		},
		methods: {
			...mapActions('http', ['post', 'get']),
			async loadRequests() {
				this.loading = true
				var {response, error} = await this.get({
                    url: `${this.$baseURL}/payout/all`,
                    headers: this.ajax_headers
                })
                this.loading = false
                if (response && response.data.success) {
                	this.requests = response.data.requests
                }
			},
			async verifyAccount() {
				this.verify.loading = true
				var {response, error} = await this.post({
                    url: `${this.$baseURL}/payout/verify-account`,
                    data: {request_id: this.request_id},
                    headers: this.ajax_headers,
                    serverError: true
                })
                this.verify.loading = false
                if (response && response.data.success) {
                	await this.setBank(response.data.bank_details)
                }
			},
			async confirmPayout() {
				if (!await swal({
					title: 'Confirm Payout',
					text: 'Are you sure you want to confirm this payout?', 
					icon: 'info', 
					buttons: true,
					dangerMode: true
				})) {
					return
				}

				this.confirm.loading = true
				var {response, error} = await this.post({
                    url: `${this.$baseURL}/payout/confirm`,
                    data: {request_id: this.request_id},
                    headers: this.ajax_headers,
                    serverError: true,
                    success: true
                })
                this.confirm.loading = false

                if (response && response.data.success) {
                	$(this.$refs.approve_modal).modal('hide')
					let updated = this.requests.find(request => request._id === response.data.payout._id)
					updated.status = 1
                }
			},
			async payout() {
				return false
				this.transfer.loading = true
				var {response, error} = await this.post({
                    url: `${this.$baseURL}/payout/transfer`,
                    data: {request_id: this.request_id},
                    headers: this.ajax_headers,
                    serverError: true,
                    success: true
                })
                this.transfer.loading = false
                if (response && response.data.success) {
                	$(this.$refs.approve_modal).modal('hide')
                }
			},
			setBank(details) {
				this.verify.data = details
			},
			callApproveModal(id) {
				this.request_id = id
				$(this.$refs.approve_modal).modal({backdrop: 'static', keyboard: false}) 
			},
			closeModal() {
				this.verify.data = null
			}
		}
	}
</script>