import swal from "sweetalert";

export default {
	namespaced: true,
	actions: {
		// Create Success Alert
		async success({dispatch}, payload) {
			// Creaye Alert
			return await dispatch("alert", {...payload, icon: "success"});
		},

		// Create Error Alert
		async error({dispatch}, payload) {
			// Creaye Alert
			return await dispatch("alert", {...payload, icon: "error"});
		},

		// Create Warning Alert
		async warning({dispatch}, payload) {
			// Creaye Alert
			return await dispatch("alert", {...payload, icon: "warning"});
		},

		// Create Warning Alert With Response
		async confirm({dispatch}, payload) {
			// Confirmation
			let confirmed;

			// Create Alert With Confirm And Cancel Buttons
			await dispatch("alert", {...payload, icon: "warning", buttons: true})
				.then(response => {
					// Assign Confirmation Response
					confirmed = response;
				});
				
			// Return Confirmation Response
			return confirmed;
		},

		// Display Loader
		async loader(store, status) {
			if (status) {
				window.jQuery('#modal-alert-loading').modal({
					backdrop: 'static',
					// keyboard: false,
					show: true
				});
			}else {
				window.jQuery('#modal-alert-loading').modal('hide');
			}
		},

		// Create Any Alert
		alert(store, payload) {
			// Return Alert Instance
			return swal(payload);
		},
	}
}