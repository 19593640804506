<template>
	<div id="reports">
		<div class="page--header mb-3">
			<h5 class="vault--text--primary">Balance Analytics</h5>
		</div>
		<div class="card p-3">
			<canvas ref="barChart" height="400"></canvas>
		</div>
		<div class="row mt-4">
			<div class="col">
				<div class="page--header mb-3">
					<h5 class="vault--text--primary">Savings Plans</h5>
				</div>
				<div class="card p-3">
					<canvas ref="pieChart" height="180" width="300"></canvas>
				</div>
			</div>
			<div class="col">
				<div class="page--header mb-3">
					<h5 class="vault--text--primary">Active Users</h5>
				</div>
				<div class="card p-3">
					<canvas ref="lineChart" height="180" width="300"></canvas>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Chart from "chart.js"
	export default {
		data() {
			return {

			}
		},
		mounted() {
			this.renderBarChart()
			this.renderPieChart()
			this.renderLineChart()
		},
		methods: {
			renderBarChart() {
				let ctx = this.$refs.barChart.getContext("2d")
				var myChart = new Chart(ctx, {
				    type: 'bar',
				    data: {
				        labels: ['RentPlus', 'Target Savings', 'Vault Lock', 'Little Drops', 'Kapital', 'Wishlist'],
				        datasets: [
				        	{
					            label: 'Plan Balance',
					            data: [280, 300, 380, 80, 250, 80],
					            backgroundColor: [
					                '#342F85',
					                '#342F85',
					                '#342F85',
					                '#342F85',
					                '#342F85',
					                '#342F85'
					            ]
					        },
					        {
					            label: 'Interest Accured',
					            data: [220, 240, 300, 40, 200, 40],
					            backgroundColor: [
					                '#2D62ED',
					                '#2D62ED',
					                '#2D62ED',
					                '#2D62ED',
					                '#2D62ED',
					                '#2D62ED'
					            ]
					        }
				        ]
				    },
				    options: {
				    	responsive: true,
				        scales: {
				            xAxes: [{
					            gridLines: {
						            display: true,
						            zeroLineColor: 'transparent'
						        },
				            	barPercentage: 1.0,
				            	barThickness: 30
					        }],
					        yAxes: [{
					            ticks: {
					                beginAtZero: true
					            }
					        }]
				        }
				    }
				})
			},
			renderPieChart() {
				let ctx = this.$refs.pieChart.getContext("2d")
				var myChart = new Chart(ctx, {
				    type: 'pie',
				    data: {
				        labels: ['RentPlus', 'Target Savings', 'Vault Lock', 'Little Drops', 'Kapital', 'Wishlist'],
				        datasets: [
				        	{
					            label: 'Plan Balance',
					            data: [280, 300, 380, 80, 250, 80],
					            backgroundColor: [
					                '#2D62ED',
					                '#410ADD',
					                '#DC0000',
					                '#E83C8F',
					                '#F88600',
					                '#990F94'
					            ]
					        }
				        ]
				    },
				    options: {
				    	responsive: true,
				    	labels: {
				    		display: false
				    	},
				    	legend: {
				    		position: 'right',
				    		labels: {
				    			padding: 10,
						      	usePointStyle: true,
						      	boxWidth: 6
						    }
				    	},
				        scales: {
				            xAxes: [{
				            	display: false
					        }],
					        yAxes: [{
					            display: false
					        }]
				        }
				    }
				})
			},
			renderLineChart() {
				let ctx = this.$refs.lineChart.getContext("2d")
				var myChart = new Chart(ctx, {
				    type: 'line',
				    data: {
				        labels: ['Feb', 'Mar', 'Apr', 'May', 'June'],
				        datasets: [
				        	{
					            label: 'Active Savers',
					            data: [280, 300, 380, 80, 250],
					            pointBackgroundColor: "#3FAD00", 
								borderWidth: 1, 
								pointBorderColor: "#3FAD00",
								backgroundColor: "transparent",
								borderColor: "#3FAD00"
					        },
					        {
					            label: 'Active Withdrawers',
					            data: [220, 240, 300, 40, 200],
					            pointBackgroundColor: "#FF3D3D", 
								borderWidth: 1, 
								pointBorderColor: "#FF3D3D",
								backgroundColor: "transparent",
								borderColor: "#FF3D3D"
					        }
				        ]
				    },
				    options: {
				    	responsive: true,
				    	legend: {
				    		labels: {
						      	usePointStyle: true,
						      	boxWidth: 6
						    }
				    	},
				        scales: {
				            xAxes: [{
				            	ticks: {
					                beginAtZero: true
					            }
					        }],
					        yAxes: [{
					            ticks: {
					                beginAtZero: true
					            }
					        }]
				        }
				    }
				})
			}
		}
	}
</script>