<template>
	<div id="admin-routes">
		<transition name="component-fade" mode="out-in">
            <router-view />
        </transition>
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
			return {
				
			}
		}
	}
</script>