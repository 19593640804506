<template>
	<div id="login">
		<div class="d-flex justify-content-center align-items-center min-vh-100">
			<form @submit.prevent="submit" class="w-25">
				<div class="text-center mb-5">
					<img src="/svg/vault-logo-dark.svg" width="150">
				</div>
				<div class="form-group">
					<label>Email</label>
					<input type="email" class="form-control" v-model="form.data.email">
					<small class="form-text form-text-error" v-if="form.errors.email">
						{{form.errors.email[0]}}
					</small>
				</div>
				<div class="form-group">
					<label>Password</label>
					<input type="password" class="form-control" v-model="form.data.password">
					<small class="form-text form-text-error" v-if="form.errors.password">
						{{form.errors.password[0]}}
					</small>
				</div>
				<div class="mt-4">
					<button
						type="submit"
						:disabled="form.loading" 
						class="btn btn-block btn--sm btn--vault--primary"
					>	
						<span v-if="form.loading">Loading...</span>
						<span v-else>Login</span>
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script type="text/javascript">
	import {mapActions} from 'vuex'
	export default {
		data() {
			return {
				form: {
					data: {
						email: '',
						password: ''
					},
					errors: {
						email: false,
						password: false
					},
					validation: {
						email: 'required',
						password: 'required'
					},
					loading: false
				}
			}
		},
		methods: {
			...mapActions('http', ['post']),
			...mapActions('form', ['validate']),
			...mapActions('user/session', ['login']),
			async submit() {
				if(!await this.validate({...this.form})) {
					return false
				}

				this.form.loading = true
				const {response, error} = await this.post({
					url: `${this.$baseURL}/auth/login`,
					data: this.form.data,
					headers: this.ajax_headers,
					serverError: true,
					formErrors: false
				})
				this.form.loading = false

				if(error && response.data.errors) {
					error.response.data.errors.forEach(error => {
						for(name in error) {
							this.form.errors[name] = error[name].split()
						}
					})
				}else if(response && response.data.success) {
					this.login({user: response.data.user, token: response.data.token})
					this.$router.push({name: 'home'})
				}
			}
		}
	}
</script>