import Vue from "vue";

Vue.mixin({
	computed: {
		ajax_headers() {
			let token = localStorage.getItem('vault24-admin-token')
			return {
				...this.$headers,
				'Authorization': `Bearer ${token}`
			}
		}
	},
});