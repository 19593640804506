import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import Money from "v-money"

import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/css/dashboard.css"


import "./filters"
import "./mixins"
import "./components"
// import "./directives"

let env = "production"

if(env == "dev") {
	Vue.prototype.$baseURL = "http://localhost:3000/vault24/api/v1/admin"
	Vue.prototype.$paystackKey = "pk_test_6335e048765d7231ecb603219b010b8cb1a7a6e6"
} else {
	Vue.prototype.$baseURL = "http://165.22.1.75/vault24/api/v1/admin"
	// Vue.prototype.$paystackKey = "pk_live_5392328483114076f7e0e30d4689265c80d8773f"
	Vue.prototype.$paystackKey = "pk_test_6335e048765d7231ecb603219b010b8cb1a7a6e6"
} 

Vue.config.productionTip = false

Vue.use(Money, {
	precision: 2,
	prefix: "₦",
	masked: false
})

Vue.prototype.$headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Authorization": "JWT fefege..." 
};

new Vue({
  	router,
  	store,
  	render: h => h(App),
}).$mount("#app")