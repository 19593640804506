import axios from "axios";

export default {
	namespaced: true,
	state: {
		loading: false
	},
	mutations: {
		mutate(state, {key, value}) {
			state[key] = value;
		},
		loading(state, status = true) {
			state.loading = status;
		}
	},
	actions: {
		// Send GET Requests
		async get({dispatch}, payload) {
			// Call Ajax Action With GET As Method
			return await dispatch("ajax", {...payload, method: "GET"});
		},
		// Send POST Requests
		async post({dispatch}, payload) {
			// Call Ajax Action With POST As Method
			return await dispatch("ajax", {...payload, method: "POST"});
		},
		// Send Any HTTP Request
		async ajax({commit, dispatch}, {method="GET", url, data, headers=[], before= ()=>{}, after= ()=>{}, mutate=false, success = false, handleError = true, serverError = false, formErrors = true, axiosProps = {}}) {
			// Request Response And Error
			let result = {response: false, error: false};

			// Activate Loading Status
			commit("loading");

			// Call Before Function
			before();

			// Send Request
			await axios({
				// Request URL
				url,
				// Request Method
				method,
				// Post Data
				data,
				// Request Headers
				headers,
				// Axios Specific Properties
				...axiosProps,
			})
			.then(response => {
				// Assign Request Response
				result.response = response;

				// Handle Responses
				dispatch("handleResponse", {response, mutate, success});

			})
			.catch(error => {
				// Assign Response Error
				result.error = error;

				// Handle Errors
				if (handleError) {
					dispatch("handleError", {...error, serverError, formErrors});
				}

			});

			// Call After Function With Response As Parameter
			after(result);

			// Deactivate Loading Status
			commit("loading", false);

			return result;
		},


		// Handle Response Data
		handleResponse({dispatch, commit}, {response, mutate, success}) {

			// No Data Was Returned
			if (!response.data) {
				console.log("No Data Was Returned");
				dispatch("alerts/warning", {
					title: "There was an error",
					text: "Please Try Again Later"
				}, {root: true});
				return;
			}

			// if Resource Data Was Returned
			if (mutate && typeof mutate == 'string') {
				var data = response.data[mutate] || response.data.data;
				commit("resources/mutate", {key: mutate, value: data}, {root: true});
			}

			if (success) {
				dispatch("alerts/success", {
					title: "Success",
					text: typeof success == 'string' ? success : response.data.message
				}, {root: true});
			}

		},

		// Handle Response Errors
		handleError({dispatch}, {response, serverError, formErrors}) {
			
			// No Response Was Returned
			if (!response) {
				console.log("No Response Was Returned");
				dispatch("alerts/error", {
					title: "There was an error",
					text: "Please try again later"
				}, {root: true});
				return;
			}
			
			// Handle Error States / Codes
			switch (response.status) {
				case 400:
					// Bad Request
					console.log("Bad Request");
					dispatch("alerts/error", {
						title: "There was an error",
						text: serverError && response.data ? response.data.message : "Please try again later"
					}, {root: true});
					break;
				case 404:
					// Not Found
					console.log("Not Found");
					dispatch("alerts/error", {
						title: "There was an error",
						text: serverError && response.data ? response.data.message : "Please try again later"
					}, {root: true});
					break;
				case 419:
					// X-CSRF-TOKEN Error
					console.log("X-CSRF-TOKEN Error");
					dispatch("alerts/error", {
						title: "Session Expired",
						text: serverError && response.data ? response.data.message : "Please reload your browser"
					}, {root: true});
					break;
				case 422:
					if (formErrors) {
						// Input Data Error
						console.log("Input Data Error");
						dispatch("alerts/error", {
							title: "Incorrect Form Data",
							text: serverError && response.data ? response.data.message : "Please check the form for incorrect data"
						}, {root: true});
					}
					break;
				case 500:
					// Server Error
					console.log("Server Error");
					dispatch("alerts/error", {
						title: "Sorry",
						text: serverError && response.data ? response.data.message : "We are currently working on it"
					}, {root: true});
					break;
				case 504:
					// Gateway Timeout
					console.log("Gateway Timeout");
					dispatch("alerts/error", {
						title: "Request Timed Out",
						text: serverError && response.data ? response.data.message : "The request took too long, please try again"
					}, {root: true});
					break;


					// ================================================================================
					// ================================================================================
					// Custom Error Codes
					// ================================================================================
					// ================================================================================
				case 449:
					// Just Try Again
					console.log("Just Try Again");
					dispatch("alerts/error", {
						title: "Please Try Again",
						text: serverError && response.data ? response.data.message : "Please just try again"
					}, {root: true});
					break;
				default:
					// Unknown Error
					console.log("Unknown Error");
					dispatch("alerts/error", {
						title: "Sorry",
						text: serverError && response.data ? response.data.message : "We are currently working on it"
					}, {root: true});
					break;
			}

		}

	}
}