<template>
	<div class="side-nav-content">
		<div class="user-profile">
			<div class="user-avatar text-center mb-3">
				<img src="/svg/user-avatar.svg" width="150">
			</div>
			<div class="user--info text-center">
				<h5 class="user--name vault--text--primary m-0 text-capitalize">{{user.firstname}} {{user.lastname}}</h5>
				<small class="user--role">Administrator</small>
			</div>
		</div>
		<nav class="nav flex-column">
			<div class="mt-4">
  				<router-link class="nav-link" :to="{ name: 'home' }">
  					<span></span>
  					<span>
	  					Home
	  				</span>
  				</router-link>
  				<router-link class="nav-link" :to="{ name: 'savings' }">
  					<span></span>
  					<span>
	  					Savings
	  				</span>
  				</router-link>
  				<router-link class="nav-link" :to="{ name: 'customers' }">
  					<span></span>
  					<span>
	  					Customers
	  				</span>
  				</router-link>
  				<router-link class="nav-link" :to="{ name: 'transactions' }">
  					<span></span>
  					<span>
	  					Transactions
	  				</span>
  				</router-link>
  				<router-link class="nav-link"  :to="{ name: 'credits' }">
  					<span></span>
  					<span>
	  					Credits
	  				</span>
  				</router-link>
  				<router-link class="nav-link"  :to="{ name: 'withdrawals' }">
  					<span></span>
  					<span>
  						Withdrawals
  					</span>
  				</router-link>
  				<router-link class="nav-link"  :to="{ name: 'reports' }">
  					<span></span>
  					<span>
  						Reports
  					</span>
  				</router-link>
  				<!-- <router-link class="nav-link"  :to="{ name: 'support' }">
  					<span></span>
  					<span>
  						Support
  					</span>
  				</router-link> -->
  				<router-link class="nav-link"  :to="{ name: 'settings' }">
  					<span></span>
  					<span>
  						Settings
  					</span>
  				</router-link>
  			</div>
		</nav>
	</div>
</template>

<script type="text/javascript">
	import {mapState} from 'vuex'
	export default {
		data() {
			return {

			}
		},
		computed: {
			...mapState('user/session', ['user'])
		}
	}
</script>