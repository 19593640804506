export default {
	namespaced: true,
	state: {
		user: null,
		loggedIn: false,
		ready: false
	},
	mutations: {
		setUser(state, user) {
			state.user = user
			state.loggedIn = !!user
		},
		removeUser(state) {
			state.user = null
			state.loggedIn = false
		},
		ready(state) {
			state.ready = true
		}
	},
	actions: {
		login({ commit, dispatch }, payload) {
			commit("setUser", payload.user)
			if(payload.token) {
				localStorage.setItem('vault24-admin-token', payload.token)
			}
		},
		logout({ commit }) {
			commit("removeUser")
			localStorage.removeItem('vault24-admin-token')
		},
		async restoreSession({ state, dispatch, commit }) {
			var data = {}
			var token = localStorage.getItem('vault24-admin-token')
			if (token) {
				var headers = {
					Authorization: `Bearer ${token}`
				};
				var { response } = await dispatch("http/get", {
					url: `${this._vm.$baseURL}/auth/user`, 
					data, 
					headers, 
					handleError: false
				}, {root: true})
				
				if (response && response.data.success) {
					dispatch('login', {user: response.data.user})
				}
			}
			commit('ready')
		}
	}
}