String.prototype.fromSlug = function() {
	return this.replace(/[\-\_]/g, ' ');
}

String.prototype.capitalize = function() {
	return this.replace(/^\w|\s\w/g, (match) => {
		return match.toUpperCase();
	});
}

String.prototype.ucFirst = function() {
	return this.replace(/^\w/g, (match) => {
		return match.toUpperCase();
	});
}

export default {
	namespaced: true,
	state: {
		rules: {
			required: {
				test(data) {
					return data != "" && data != null && data != undefined;
				},
				message(name) {
					return `the ${name.fromSlug()} field is required`.ucFirst();
				}
			},
			requiredIf: {
				test(data, condition, params) {
					switch (condition) {
						case 'is':
							break;
						default:

							break;
					}
				},
				message(name) {
					return `the ${name.fromSlug()} field is required`.ucFirst();
				}
			},
			is: {
				test(data, value) {
					return data == value;
				},
				message(name, value) {
					return `the ${name.fromSlug()} field must be ${value}`.ucFirst();
				},
				param: true
			},
			not: {
				test(data, value) {
					return data != value;
				},
				message(name, value) {
					return `the ${name.fromSlug()} field must NOT be ${value}`.ucFirst();
				},
				param: true
			},
			email: {
				test(data) {
					return data && data.toString().match();
				},
				message() {
					return `this email is invalid`.ucFirst();
				}
			},
			alpha: {
				test(data) {
					return data && data.toString().match(/^[a-z A-Z]+$/);
				},
				message(name) {
					return `the ${name.fromSlug()} field can only contain letters`.ucFirst();
				}
			},
			name: {
				test(data) {
					return data && data.toString().split(' ').length > 1;
				},
				message(name) {
					return `the ${name.fromSlug()} has to be a proper full name`.ucFirst();
				}
			},
			number: {
				test(data) {
					return data && data.toString().match(/^[0-9]+$/);
				},
				message(name) {
					return `the ${name.fromSlug()} field can only contain numbers`.ucFirst();
				}
			},
			phone: {
				test(data) {
					return data && data.toString().match(/^(\+|)(234|0)(7|8|9)(0|1)\d{8}$/);
				},
				message() {
					return `the phone number has to be a valid nigerian number`.ucFirst();
				}
			},
			min: {
				test(data, min) {
					if (!data) {
						return false;
					}
					if (typeof min !== "number" && isNaN(min)) {
						throw new new Error("rule parameter 'min' has to be a number");
					}
					if (typeof data == "number") {
						return data >= min;
					}else {
						return data.toString().length >= min;
					}
				},
				message(name, min) {
					return `the ${name.fromSlug()} field has to contain at least ${min} characters`.ucFirst();
				},
				param: true
			},
			max: {
				test(data, max) {
					if (!data) {
						return false;
					}
					if (typeof max !== "number" && isNaN(max)) {
						throw new new Error("rule parameter 'max' has to be a number");
					}
					if (typeof data == "number") {
						return data <= max;
					}else {
						return data.toString().length <= max;
					}
				},
				message(name, max) {
					return `the ${name.fromSlug()} field has to contain less than ${max} characters`.ucFirst();
				},
				param: true
			},
			length: {
				test(data, length) {
					if (!data) {
						return false;
					}
					if (typeof length == "number" && isNaN(length)) {
						throw new new Error("rule parameter 'length' has to be a number");
					}
					if (typeof data == "number") {
						return data == length;
					}else {
						return data.toString().length == length;
					}
				},
				message(name, length) {
					return `the ${name.fromSlug()} field has to be exactly ${length} characters`.ucFirst();
				},
				param: true
			},
			inArray: {
				test(data, array) {
					return data && array.find(item => item == data);
				},
				message(name, array) {
					return `the ${name.fromSlug()} field has to contain any of these ${array.join(', ')}`.ucFirst();
				},
				params: true
			},
			notInArray: {
				test(data, array) {
					return data && array.find(item => item !== data);
				},
				message(name, array) {
					return `the ${name.fromSlug()} field cannot contain any of these ${array.join(', ')}`.ucFirst();
				},
				params: true
			},
			file: {
				test(element) {
					return element && element.files && element.files.length;
				},
				message(name) {
					return `a file has to be chosen for the ${name.fromSlug()} field`.ucFirst();
				},
				element: true
			},
			size: {
				test(data) {

				},
				message(name) {
					return `the file ${name.fromSlug()}`.ucFirst();
				}
			},
		}
	},
	actions: {
		async validate({dispatch}, {data, errors, validation, form}) {
			let length = Object.keys(validation).length;
			let validLength = 0;
			for (let name in validation) {
				if (
					await dispatch("ValidateField", {
						// v-model / bound data
						data: data[name],
						// Field Name
						name,
						// Validation Rules For Field
						rules: validation[name],
						// Field Element By ID
						element: form && form.querySelector(`#${name}`),
						// Errors
						errors
					})
				) {
					validLength++;
				}
			}
			return length == validLength;
		},
		async ValidateField({state}, {data, name, rules, errors, element}) {
			// If Rules are not provided
			if (!rules) {
				throw new new Error("Invalid Validation Rules");
			}

			let valid = true;
			let errorBag = [];

			if (rules.constructor.toString().match(/Function/)) {
				valid = rules(data);
				if (valid === true) {
					errors[name] = false;
					return true;
				}

				// Invalid Returns Error Message
				errors[name] = [valid];
				return false;
			}

			// Split Rules
			rules.split('|').forEach(rule => {
				rule = rule.split(":");
				let ruleName = rule.shift();
				let params = rule.length ? rule.pop().split(",") : [];

				// Check If Rule Exists In Collection
				if (!state.rules[ruleName]) {
					return;
				}

				// Get Rule From Collection
				let RULE = state.rules[ruleName];
				if (
					!RULE.test(
						// First Parameter
						RULE.element ? element : data,
						// Second Parameter
						RULE.param ? params.join(',') : RULE.params ? params : null
					)
				) {
					valid = false;
					errorBag.push(
						RULE.message(
							// First Parameter
							name,
							// Secomd Parameter
							RULE.param ? params.join(',') : RULE.params ? params : null
						)
					);
				}

			});

			if (valid) {
				errors[name] = false;
			}else {
				errors[name] = errorBag;
			}

			return valid;
		},
	}
}