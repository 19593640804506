<template>
	<div id="user-management">
		<div class="d-flex justify-content-between align-items-center mb-3">
			<h5 class="m-0 text--primary">Users</h5>
			<button class="btn btn--sm btn--vault--primary" @click="showAddUserModal">Add User</button>
		</div>
		<div class="card table-responsive pb-0">
			<table class="table mb-0">
			  	<thead>
			    	<tr>
			      		<th scope="col">User</th>
			      		<th scope="col">Email</th>
			      		<th scope="col">Active Since</th>
			      		<th scope="col">Role</th>
			      		<th scope="col">Action</th>
			    	</tr>
			  	</thead>
				<tbody v-if="users.loading">
					<tr>
						<td colspan="5" align="center">
							<ajax-loader/>
						</td>
					</tr>
				</tbody>
			  	<tbody v-else-if="users.data && users.data.length">
			  		<template v-for="(user, n) in users.data">
				    	<tr :key="n">
				      		<td>
				      			<div class="d-flex justify-content-start align-items-center td--img">
					      			<img src="/svg/user-avatar.svg" class="img-fluid">
					      			<span class="ml-2 text-capitalize">{{user.firstname}} {{user.lastname}}</span>
					      		</div>
				      		</td>
				      		<td>{{user.email}}</td>
				      		<td>{{user.created_at}}</td>
				      		<td class="text-capitalize">{{user.role}}</td>
				      		<td class="d-flex justify-content-start">
				      			<button class="btn btn--xs btn--vault--primary" @click="showEditModal(user)">Edit</button>
				      			<button class="btn btn--xs btn--danger ml-2">Deactivate</button>
				      		</td>
				    	</tr>
			    	</template>
			  	</tbody>
				<tbody v-else>
					<tr><td colspan="5" align="center">No user added yet!</td></tr>
				</tbody>
			</table>
		</div>
		<div class="modal fade"tabindex="-1" role="dialog" aria-hidden="true" ref="edit_modal" v-if="selected_user">
		  	<div class="modal-dialog modal-dialog-centered" role="document">
		    	<div class="modal-content">
		      		<div class="modal-body p-5">
		      			<div class="d-flex justify-content-between mb-4">
		      				<div class="d-flex justify-content-start align-items-center">
		      					<div class="user--modal--avatar">
		      						<img src="/svg/user-avatar.svg" class="img-fluid">
		      					</div>
		      					<div class="user--modal--text ml-3">
		      						<h6 class="vault--text--primary m-0 font--weight--400">{{selected_user.name}}</h6>
		      						<small>{{selected_user.email}}</small>
		      					</div>
		      				</div>
			      			<div class="close" data-dismiss="modal" aria-label="Close">
			          			<ion-icon name="close-circle-outline" class="cursor vault--text--primary"/>
			        		</div>
			        	</div>
						<div class="mt-4 text-center">
							<form>
								<select class="custom-select">
									<option value="super admin">Super Admin</option>
									<option value="customer service">Customer Service</option>
									<option value="finance">Finance</option>
								</select>
								<div class="mt-5">
									<button class="btn btn--sm btn--vault--primary px-5" type="submit">Save</button>
								</div>
							</form>
						</div>
		      		</div>
		    	</div>
		  	</div>
		</div>
		<div class="modal fade"tabindex="-1" role="dialog" aria-hidden="true" ref="add_user_modal">
		  	<div class="modal-dialog modal-dialog-centered" role="document">
		    	<div class="modal-content">
		      		<div class="modal-body p-5">
		      			<div class="d-flex justify-content-between align-items-center mb-4">
		      				<h5 class="m-0 text--primary">New User</h5>
			      			<div class="close" data-dismiss="modal" aria-label="Close">
			          			<ion-icon name="close-circle-outline" class="cursor vault--text--primary"/>
			        		</div>
			        	</div>
						<div class="mt-4">
							<form @submit.prevent="addUser">
								<div class="form-group">
									<label>First Name</label>
									<input type="text" class="form-control" v-model="form.data.firstname">
									<small class="form-text form-text-error" v-if="form.errors.firstname">
										{{form.errors.firstname[0]}}
									</small>
								</div>
								<div class="form-group">
									<label>Last Name</label>
									<input type="text" class="form-control" v-model="form.data.lastname">
									<small class="form-text form-text-error" v-if="form.errors.lastname">
										{{form.errors.lastname[0]}}
									</small>
								</div>
								<div class="form-group">
									<label>Email</label>
									<input type="email" class="form-control" v-model="form.data.email">
									<small class="form-text form-text-error" v-if="form.errors.email">
										{{form.errors.email[0]}}
									</small>
								</div>
								<div class="form-group">
									<label>Role</label>
									<select class="custom-select" v-model="form.data.role">
										<option value="super admin">Super Admin</option>
										<option value="customer service">Customer Service</option>
										<option value="finance">Finance</option>
									</select>
									<small class="form-text form-text-error" v-if="form.errors.role">
										{{form.errors.role[0]}}
									</small>
								</div>
								<div class="mt-5 text-center">
									<button class="btn btn--sm btn--vault--primary px-5" type="submit">
										<span v-if="form.loading">Loading...</span>
										<span v-else>Add User</span>
									</button>
								</div>
							</form>
						</div>
		      		</div>
		    	</div>
		  	</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import {mapActions} from 'vuex'
	import $ from 'jquery'
	export default {
		data() {
			return {
				form: {
					data: {
						firstname: '',
						lastname: '',
						email: '',
						role: ''
					},
					errors: {
						firstname: false,
						lastname: false,
						email: false,
						role: false
					},
					validation: {
						firstname: 'required',
						lastname: 'required',
						email: 'required',
						role: 'required'
					},
					loading: false
				},
				users: {
					loading: false,
					data: null
				},
				selected_user: null
			}
		},
		mounted() {
			this.loadUsers()
		},
		methods: {
			...mapActions("http", ["post", "get"]),
			...mapActions('form', ['validate']),
			async loadUsers() {
				this.users.loading = true
                var {response, error} = await this.get({
                    url: `${this.$baseURL}/users`,
                    headers: this.ajax_headers,
                    formErrors: false
                })
				this.users.loading = false

                if (response && response.data.success) {
                	this.users.data = response.data.users
                }
			},
			async addUser() {
				if(!await this.validate({...this.form})) {
					return false
				}

				this.form.loading = true
				const {response, error} = await this.post({
					url: `${this.$baseURL}/users/new-user`,
					data: this.form.data,
					headers: this.ajax_headers,
					success: true,
					serverError: true,
					formErrors: false
				})
				this.form.loading = false

				if(error && response.data.errors) {
					error.response.data.errors.forEach(error => {
						for(name in error) {
							this.form.errors[name] = error[name].split()
						}
					})
				}else if(response && response.data.success) {
					$(this.$refs.add_user_modal).modal('hide')
					this.users.data = {...this.user.data, ...response.data.user}
				}
			},
			setUser(user) {
				this.selected_user = user
			},
			async showEditModal(user) {
				await this.setUser(user)
				$(this.$refs.edit_modal).modal({backdrop: "static", keyboard: false})
			},
			showAddUserModal() {
				$(this.$refs.add_user_modal).modal({backdrop: "static", keyboard: false})
			}
		}
	}
</script>