<template>
	<div id="product-management">
		<div class="d-flex justify-content-between align-items-center mb-3">
			<h5 class="m-0 text--primary">Products</h5>
			<button class="btn btn--sm btn--vault--primary">Add Product</button>
		</div>
		<div class="card table-responsive pb-0">
			<table class="table mb-0">
			  	<thead>
			    	<tr>
			      		<th scope="col">Product</th>
			      		<th scope="col">Total Balance</th>
			      		<th scope="col">Interest Rate</th>
			      		<th scope="col">Active Plans</th>
			      		<th scope="col">Last Modified</th>
			      		<th scope="col">Action</th>
			    	</tr>
			  	</thead>
			  	<tbody>
			  		<template>
				    	<tr v-for="(product) in products">
				      		<td>{{product.name}}</td>
				      		<td>{{product.balance}}</td>
				      		<td>{{product.interest}}</td>
				      		<td>{{product.plans}}</td>
				      		<td>{{product.updated_at}}</td>
				      		<td class="d-flex justify-content-start">
				      			<button class="btn btn--xs btn--vault--primary" @click="showEditModal(product)">
				      				Edit
				      			</button>
				      			<button class="btn btn--xs btn--danger ml-2">Deactivate</button>
				      		</td>
				    	</tr>
				    </template>
			  	</tbody>
			</table>
		</div>
		<div class="modal fade"tabindex="-1" role="dialog" aria-hidden="true" ref="edit_modal" v-if="selected_product">
		  	<div class="modal-dialog modal-dialog-centered" role="document">
		    	<div class="modal-content">
		      		<div class="modal-body p-5">
		      			<div class="d-flex justify-content-end mb-2">
			      			<div class="close" data-dismiss="modal" aria-label="Close">
			          			<ion-icon name="close-circle-outline" class="cursor vault--text--primary"/>
			        		</div>
			        	</div>
			        	<h5 class="text-center mb-4 vault--text--primary">Edit {{selected_product.name}}</h5>
						<form>
							<div class="form-group">
								<label class="vault--text--primary">Interest Rate (%)</label>
								<input type="number" class="form-control">
							</div>
							<div class="form-group">
								<label class="vault--text--primary">Bonus Interest Rate (%)</label>
								<input type="number" class="form-control">
							</div>
							<div class="form-group">
								<div class="mt-3 mb-2 d-flex justify-content-center align-items-center">
						    		<div class="custom-checkbox">
										<label class="m-">
										  	<input type="checkbox">
										  	<span></span>
										</label>
									</div>
					                <small class="text-muted ml-2" style="margin-top: -.35rem">
					                	Apply changes to existing savers.
					                </small>
					            </div>
							</div>
							<div class="mt-5 text-center">
								<button class="btn btn--sm btn--vault--primary px-5" type="submit">Save</button>
							</div>
						</form>
		      		</div>
		    	</div>
		  	</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import $ from 'jquery'
	export default {
		data() {
			return {
				products: [
					{
						id: 1,
						name: 'RentPlus',
						balance: '₦1,540,990',
						interest: '8%',
						plans: '100',
						updated_at: '9 Sept. 2020'
					},
					{
						id: 2,
						name: 'Target Savings',
						balance: '₦540,990',
						interest: '8%',
						plans: '270',
						updated_at: '9 May. 2020'
					},
					{
						id: 3,
						name: 'Vault Lock',
						balance: '₦10,577,500',
						interest: '13%',
						plans: '18',
						updated_at: '23 Feb. 2021'
					},
					{
						id: 4,
						name: 'Little Drops',
						balance: '₦1,540,990',
						interest: '8%',
						plans: '100',
						updated_at: '9 Sept. 2020'
					},
					{
						id: 5,
						name: 'Kapital',
						balance: '₦1,540,990',
						interest: '8%',
						plans: '2',
						updated_at: '12 Aug. 2021'
					},
					{
						id: 6,
						name: 'Wishlist',
						balance: '₦1,540,990',
						interest: '8%',
						plans: '5',
						updated_at: '29 Aug. 2021'
					}
				],
				selected_product: null
			}
		},
		methods: {
			setProduct(product) {
				this.selected_product = product
			},
			async showEditModal(product) {
				await this.setProduct(product)
				$(this.$refs.edit_modal).modal({backdrop: "static", keyboard: false})
			}
		}
	}
</script>