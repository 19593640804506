import Vue from "vue"
import moment from "moment"

Vue.mixin({
	filters: {
		currency (number, places = 2) {
			if (!number) {
				return number;
			}
			if (typeof number == "string") {
				number = number.replace(/,/i, '')
			}
			return Number(number).toFixed(places).replace(/\d(?=(\d{3})+\.)/g, '$&,');
		},
		toDate(date) {
			return moment(date).format('MMM Do, YYYY')
		},
		fromNow(date) {
			return moment(date, "YYYYMMDD").fromNow(true)
		},
		toTime(date) {
			return moment(date).format("YYYY-MM-DD")
		},
		truncate(string, length) {
			if (!string) {
				return string;
			}
			if (string.toString().length > length) {
				return string.toString().substring(0, length) + "......"
			}
			return string;
		}
	}
})