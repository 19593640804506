import store from "./store"

export default async (router, to, from, next) => {

	// Check readiness of the session store
	while (!store.state.user.session.ready) {
		await store.dispatch("user/session/restoreSession", {}, {root: true})
	}

	// Check if user is loggedin
	if(to.matched.some(record => record.meta.requiresAuth)) {

		if (!store.state.user.session.loggedIn) {
			return next({
				name: "login",
				params: {
					nextUrl: to.fullPath
				}
			});
		}

	}else if(to.matched.some(record => record.meta.guest)) {

		if(store.state.user.session.loggedIn){
			next({ name: "home"})
		}

	}else {
		next()
	}


}

