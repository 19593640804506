<template>
	<div id="customers">
		<div class="page--header mb-4">
			<h5 class="vault--text--primary">Customers</h5>
		</div>
		<div v-if="loading">
			<div class="d-flex justify-content-center">
				<ajax-loader :loadingText="loadingText" />
			</div>
		</div>
		<div class="row" v-else-if="customers && customers.length">
			<template v-for="(customer, n) in customers">
				<div class="col-6 mb-3" :key="n">
					<div class="card p-3" style="cursor: pointer;" @click="showCustomerModal(customer)">
						<div class="d-flex justify-content-start customer--content">
							<div class="user--picture">
								<img :src="customer.avatar ? customer.avatar : '/svg/user-avatar.svg'">
							</div>
							<div class="user--info ml-3 w-100">
								<div class="d-flex justify-content-between">
									<div>
										<h6 class="m-0 text-capitalize">{{customer.firstname}} {{customer.lastname}}</h6>
										<small>{{customer.email}}</small>
									</div>
									<div>
										<ion-icon name="flag" class="text--light--gray" />
									</div>
								</div>
								<hr style="margin-top: .6rem; margin-bottom: .6rem">
								<div class="d-flex justify-content-between">
									<div>
										<small class="text--light--gray">Vault Balance</small>
										<h6 class="customer--content--value mt-1 text-center">
											{{balance(customer.plans)}}	
										</h6>
									</div>
									<div>
										<small class="text--light--gray">Interest Earned</small>
										<h6 class="customer--content--value mt-1 text-center">
											{{interest(customer.plans)}}	
										</h6>
									</div>
									<div>
										<small class="text--light--gray">Next Withdrawal</small>
										<h6 class="customer--content--value mt-1 text-center">NIL</h6>
									</div>
									<div class="align-self-stretch">
										<small class="text--light--gray">Active Plans</small>
										<h6 class="customer--content--value mt-1 text-center">
											{{customer.plans.length}}
										</h6>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
		</div>
		<div v-else>
			
		</div>
		<!-- <div class="d-flex justify-content-end mt-5">
			<nav aria-label="navigation">
			  	<ul class="pagination">
				    <li class="page-item active">
				    	<a class="page-link" href="#">
				    		<ion-icon name="chevron-back-outline"/>
				    	</a>
				    </li>
				    <li class="page-item"><a class="page-link" href="#">1</a></li>
				    <li class="page-item"><a class="page-link" href="#">2</a></li>
				    <li class="page-item"><a class="page-link" href="#">3</a></li>
				    <li class="page-item"><a class="page-link" href="#">4</a></li>
				    <li class="page-item"><a class="page-link" href="#">5</a></li>
				    <li class="page-item"><span class="text--light--gray">...</span></li>
				    <li class="page-item"><a class="page-link" href="#">30</a></li>
				    <li class="page-item">
				    	<a class="page-link" href="#">
				    		<ion-icon name="chevron-forward-outline"/>
					    </a>
					</li>
			  	</ul>
			</nav>
		</div> -->
		<div class="modal fade"tabindex="-1" role="dialog" aria-hidden="true" ref="customer_modal" v-if="selected_customer">
		  	<div class="modal-dialog modal-dialog-centered customer-modal" role="document">
		    	<div class="modal-content">
		      		<div class="modal-body p-5">
		      			<div class="d-flex justify-content-between mb-4">
		      				<div class="d-flex justify-content-start align-items-center">
		      					<div class="user--modal--avatar">
		      						<img src="/svg/user-avatar.svg" class="img-fluid">
		      					</div>
		      					<div class="user--modal--text ml-3">
		      						<h6 class="vault--text--primary m-0 font--weight--400">
		      							{{selected_customer.firstname}} {{selected_customer.lastname}}
		      						</h6>
		      						<small>{{selected_customer.email}}</small>
		      					</div>
		      				</div>
			      			<div class="close" data-dismiss="modal" aria-label="Close">
			          			<ion-icon name="close-circle-outline" class="cursor vault--text--primary"/>
			        		</div>
			        	</div>
			        	<div class="row mb-4">
	      					<div class="col-6 pr-2">
		      					<div class="card p-3">
		      						<h6 class="vault--text--primary font--weight--400">
		      							User Details
		      						</h6>
		      						<div style="margin-bottom: .5rem">
			      						<small class="text--muted d-block">
			      							Email Address
			      						</small>
			      						<small class="text--dark">
			      							{{selected_customer.email}}
			      						</small>
			      					</div>
			      					<div class="mb-">
			      						<small class="text--muted d-block">
			      							Phone Number
			      						</small>
			      						<small class="text--dark">
			      							{{selected_customer.phoneNumber}}
			      						</small>
			      					</div>
		      					</div>
		      				</div>
		      				<div class="col-6 pl-2">
		      					<div class="card p-3">
		      						<h6 class="vault--text--primary font--weight--400">
		      							Bank Details
		      						</h6>
		      						<div style="margin-bottom: .5rem">
			      						<small class="text--muted d-block">
			      							Bank Number
			      						</small>
			      						<small class="text--dark">
			      							NIL
			      						</small>
			      					</div>
			      					<div class="mb-">
			      						<small class="text--muted d-block">
			      							Account Number
			      						</small>
			      						<small class="text--dark">
			      							NIL
			      						</small>
			      					</div>
		      					</div>
		      				</div>
	      				</div>
	      				<div class="card p-3">
	      					<h6 class="vault--text--primary font--weight--400">
      							Plan Details
      						</h6>
      						<div v-if="selected_customer.plans && selected_customer.plans.length">
	      						<div class="card table-responsive pb-0">
									<table class="table mb-0">
									  	<thead>
									    	<tr>
									      		<th scope="col">S/N</th>
									      		<th scope="col">Plan</th>
									      		<th scope="col">Balance</th>
									      		<th scope="col">Interest</th>
									      		<th scope="col">Maturity</th>
									      		<th scope="col">Widthrawal</th>
									    	</tr>
									  	</thead>
									  	<tbody>
									  		<template v-for="(plan, n) in selected_customer.plans">
										    	<tr key="n">
										    		<th>{{n+1}}</th>
										      		<td>{{plan.plan_title}}</td>
										      		<td>₦{{(plan.balance ? plan.balance : '0.00') | currency}}</td>
										      		<td>
										      			₦{{(plan.interest_balance ? plan.interest_balance : '0.00') | currency}}
										      		</td>
										      		<td>{{plan.due_date | toDate}}</td>
										      		<td>NIL</td>
										    	</tr>
										    </template>
									  	</tbody>
									</table>
								</div>
							</div>
							<div v-else>
								<div class="card table-responsive pb-0">
									<table class="table mb-0">
									  	<thead>
									    	<tr>
									      		<th scope="col">S/N</th>
									      		<th scope="col">Plan</th>
									      		<th scope="col">Balance</th>
									      		<th scope="col">Interest</th>
									      		<th scope="col">Maturity</th>
									      		<th scope="col">Widthrawal</th>
									    	</tr>
									  	</thead>
									  	<tbody>
									  		<tr>
									  			<td colspan="6" align="center">
									  				<div class="d-flex justify-content-center align-items-center">
										  				<ion-icon name="close-circle-outline" style="font-size: 25px" class="text-danger"/>
										  				<span 
										  					class="ml-1" 
										  					style="font-size: 15px"
										  				>	
										  					No plan created yet
										  				</span>
										  			</div>
									  			</td>
									  		</tr>
									  	</tbody>
									</table>
								</div>
							</div>
	      				</div>
		      		</div>
		    	</div>
		  	</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import $ from 'jquery'
	import { mapActions } from "vuex"
	export default {
		data() {
			return {
				loading: true,
				loadingText: '',
				customers: [],
				selected_customer: null
			}
		},
		mounted() {
			this.loadCustomers()
		},
		methods: {
			...mapActions("http", ["post", "get"]),
			async loadCustomers() {
				this.loading = true 
				this.loadingText = "Getting customers..."

                var { response, error } = await this.get({
                    url: `${this.$baseURL}/customers/all`,
                    headers: this.ajax_headers,
                    formErrors: false
                })

                if (response && response.data.success) {
                	this.loadingText = "Populating customers..."
                	setTimeout(() => {
                		this.loading = false
                		this.loadingText = ''
                		this.customers = response.data.customers
                	}, 2000)
                }else {
                	this.loading = false
                }
			},
			setCustomer(customer) {
				this.selected_customer = customer
			},
			async showCustomerModal(customer) {
				await this.setCustomer(customer)
				$(this.$refs.customer_modal).modal({backdrop: "static", keyboard: false})
			},
			balance(plans) {
				if(!plans.length) {
					return '₦0.00'
				}

				let planBalance = plans.reduce((acc, plan) => acc + plan.balance, 0)
				if(planBalance > 0) {
					return `₦${this.$options.filters.currency(planBalance)}`
				}

				return '₦0.00'
			},
			interest(plans) {
				if(!plans.length) {
					return '₦0.00'
				}

				let interestBalance = plans.reduce((acc, plan) => acc + plan.interest_balance, 0)
				if(interestBalance > 0) {
					return `₦${this.$options.filters.currency(interestBalance)}`
				}

				return '₦0.00'
			}
		}
	}
</script>