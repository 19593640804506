export default {
	namespaced: true,
	modules: {
		session: require('./user/session').default,
	},
	state: {

	},
	mutations: {

	},
	actions: {

	}
}