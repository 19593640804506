<template>
    <div id="loader">       
        <div class="loader-wrapper d-flex justify-content-center">
            <svg
              class="loader"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              height="50"
              width="50"
            >
                <defs>
                    <linearGradient id="Gradient1" gradientTransform="rotate(90)">
                        <stop offset="0%" stop-color="#d2d2f5" />
                        <stop offset="100%" stop-color="#d2d2f5" />
                    </linearGradient>
                    <linearGradient id="Gradient2" gradientTransform="rotate(90)">
                        <stop offset="0%" stop-color="#1C1B57" />
                        <stop offset="100%" stop-color="#d2d2f5" />
                    </linearGradient>
                    <pattern
                        id="Pattern"
                        x="0"
                        y="0"
                        width="50"
                        height="50"
                        patternUnits="userSpaceOnUse"
                    >
                        <g transform="rotate(0, 25, 25)">
                            <rect
                                shape-rendering="crispEdges"
                                x="0"
                                y="0"
                                width="50"
                                height="100"
                                fill="url(#Gradient1)"
                            />
                            <rect
                                shape-rendering="crispEdges"
                                x="25"
                                y="0"
                                width="25"
                                height="50"
                                fill="url(#Gradient2)"
                            />
                        </g>
                    </pattern>
                </defs>
                <circle
                    cx="25"
                    cy="25"
                    r="23"
                    stroke="#6a4ee1"
                    style="stroke: url(#Pattern);"
                    stroke-width="3"
                    fill="transparent"
                />
            </svg>
        </div>
        <div class="text-center loading-text mt-1">
            <small class="text-muted">{{ loadingText }}</small>
        </div>  
    </div>
</template>

<script type="text/javascript">
    export default {
        props: {
            loadingText: {
                type: String
            }
        }
    }
</script>

<style type="text/css" scoped>
    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }  
    .loader {
        animation: spin .5s linear infinite;
        display: block;
        height: 100%;
    }
    /*for overlaying*/
    #overlay {   
        position: fixed;
        top: 0;
        z-index: 100;
        width: 100%;
        height:100%;
        display: none;
        background: rgba(0,0,0,0.6);
    }
    .cv-spinner {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;  
    }
</style>