<template>
	<div id="admin">
		<div id="top-nav">
			<top-nav />
		</div>
		<div class="admin--layout">
			<div class="side-nav">
				<side-nav />
			</div>
			<div class="main-content">
				<transition name="component-fade" mode="out-in">
		        	<router-view />
		        </transition>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
			return {
				
			}
		}
	}
</script>